
import { ComponentCreator, createComponentCreator, styleInject } from 'packages/css-component-modules';

const _css = `.NotificationLink_189ad1d3ca03d04a{width:24px;height:24px;display:flex;justify-content:center;align-items:center}.NotificationCounter_2039c44c00d696c4{position:absolute;overflow:hidden;border-radius:100%;width:20px;height:20px;top:7px;right:7px;padding-top:2px;text-align:center;background-color:var(--notificationCounterBackgroundColor_83ffa37d2c947b3d)}.HeavyFontWeight_e44942b6e53d8aaa{font-weight:700}.NotificationV2Link_b4ac90b54ab02578{width:24px;height:24px;display:flex;justify-content:center;align-items:center}.NotificationV2Counter_4c009e5bac3dc664{position:absolute;overflow:hidden;border-radius:100%;width:9px;height:9px;top:25px;right:7px;padding-top:2px;text-align:center;background-color:red}@media (min-width:600px){.NotificationCounter_2039c44c00d696c4{right:15px}.NotificationV2Counter_4c009e5bac3dc664{right:20px}}`;
styleInject(_css)

/** NotificationLink Props */
export type NotificationLinkCCM = {
  /** NotificationLink Component Custom Properties */
  // No custom properties found

  /** NotificationLink Modifier Flags */
  // No modifiers classes found
};
/** Base NotificationLink component */
export const NotificationLink: ComponentCreator<NotificationLinkCCM> = createComponentCreator({
  "name": "NotificationLink",
  "base": "NotificationLink_189ad1d3ca03d04a",
  "prop": {},
  "mod": {}
});


/** NotificationCounter Props */
export type NotificationCounterCCM = {
  /** NotificationCounter Component Custom Properties */
  '$notificationCounterBackgroundColor': string;

  /** NotificationCounter Modifier Flags */
  // No modifiers classes found
};
/** Base NotificationCounter component */
export const NotificationCounter: ComponentCreator<NotificationCounterCCM> = createComponentCreator({
  "name": "NotificationCounter",
  "base": "NotificationCounter_2039c44c00d696c4",
  "prop": {
    "$notificationCounterBackgroundColor": "--notificationCounterBackgroundColor_83ffa37d2c947b3d"
  },
  "mod": {}
});


/** HeavyFontWeight Props */
export type HeavyFontWeightCCM = {
  /** HeavyFontWeight Component Custom Properties */
  // No custom properties found

  /** HeavyFontWeight Modifier Flags */
  // No modifiers classes found
};
/** Base HeavyFontWeight component */
export const HeavyFontWeight: ComponentCreator<HeavyFontWeightCCM> = createComponentCreator({
  "name": "HeavyFontWeight",
  "base": "HeavyFontWeight_e44942b6e53d8aaa",
  "prop": {},
  "mod": {}
});


/** NotificationV2Link Props */
export type NotificationV2LinkCCM = {
  /** NotificationV2Link Component Custom Properties */
  // No custom properties found

  /** NotificationV2Link Modifier Flags */
  // No modifiers classes found
};
/** Base NotificationV2Link component */
export const NotificationV2Link: ComponentCreator<NotificationV2LinkCCM> = createComponentCreator({
  "name": "NotificationV2Link",
  "base": "NotificationV2Link_b4ac90b54ab02578",
  "prop": {},
  "mod": {}
});


/** NotificationV2Counter Props */
export type NotificationV2CounterCCM = {
  /** NotificationV2Counter Component Custom Properties */
  // No custom properties found

  /** NotificationV2Counter Modifier Flags */
  // No modifiers classes found
};
/** Base NotificationV2Counter component */
export const NotificationV2Counter: ComponentCreator<NotificationV2CounterCCM> = createComponentCreator({
  "name": "NotificationV2Counter",
  "base": "NotificationV2Counter_4c009e5bac3dc664",
  "prop": {},
  "mod": {}
});


function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import { createElement, useMemo, lazy, useEffect } from 'react';
import { SuspenseHandler } from 'apps/acp/packages/react-suspense-handler';
import { useLocation, useRouteActive } from 'packages/react-nano-router';
import { useSomeRoutesActive, useLayoutOverlay } from './hooks';
import { LayoutOverlay } from './layout-overlay';
import { AppBar } from './app-bar';
import { useTheme } from './theme';
import { LayoutBody, StyledLayout, MainSection, MainSectionV2 } from './layout.ccm.css';
import { LogoProvider } from 'apps/acp/packages/logo-locator';
import { AdaChatButton } from 'apps/acp/packages/ada-chatbot';
import { FeatureName, useFeatureRequest } from './api';
import { useIsReactNativeWebView } from 'packages/use-is-react-native-webview';
const BottomBar = lazy(() => import('./bottom-bar'));

const InternalLayout = ({
  appVersion,
  logoLocator,
  children,
  fallbackNavbarItems,
  homeLink,
  navbarItems,
  notificationsLink,
  appBarInverted,
  statusBarPlugin,
  statusBarBackgroundColor,
  statusBarForegroundColor,
  variantId
}) => {
  var _a;

  const links = useMemo(() => navbarItems.map(item => item.link), [navbarItems]);
  const isReactNativeWebview = useIsReactNativeWebView();
  const {
    pathname
  } = useLocation();
  const isSelfEnrollmentDashboard = pathname.includes('self-enrollment');
  const isRootLinkActive = useSomeRoutesActive(links, true) || isSelfEnrollmentDashboard;
  const {
    layoutGreyBackgroundColor,
    layoutWhiteBackgroundColor
  } = useTheme();
  const isHome = useRouteActive(homeLink, {
    exact: true
  }) || isSelfEnrollmentDashboard;
  const overlay = useLayoutOverlay();
  const {
    features
  } = useFeatureRequest(FeatureName.VERSION_2_LAYOUT);
  const isNewVersionLayout = (_a = features) === null || _a === void 0 ? void 0 : _a[FeatureName.VERSION_2_LAYOUT];
  const layoutHeight = isNewVersionLayout ? '80px' : '48px';
  useEffect(() => {
    statusBarPlugin.setStatusBarColor(statusBarBackgroundColor, statusBarForegroundColor);
  }, [statusBarPlugin, statusBarBackgroundColor, statusBarForegroundColor]);
  return createElement(LogoProvider, {
    logoLocator: logoLocator
  }, overlay.length > 0 ? createElement(LayoutOverlay, {
    id: overlay[0].id
  }) : null, createElement(StyledLayout.div, {
    $backgroundColor: layoutWhiteBackgroundColor,
    "data-testid": variantId
  }, createElement(SuspenseHandler, null, createElement(AppBar, {
    homeLink: homeLink,
    notificationsLink: notificationsLink,
    inverted: appBarInverted,
    isNewVersion: isNewVersionLayout,
    height: layoutHeight
  })), createElement(LayoutBody.div, {
    $backgroundColor: layoutGreyBackgroundColor
  }, !isReactNativeWebview && createElement(BottomBar, {
    appVersion: appVersion,
    fallbackNavbarItems: fallbackNavbarItems,
    lock: overlay.length > 0 && overlay[0].id === 'DashboardOverlay:NavigationUpdate',
    navbarItems: navbarItems,
    showMobileBottomBar: isRootLinkActive,
    height: layoutHeight
  }), isNewVersionLayout ? createElement(MainSectionV2.div, {
    $withMobileAppBar: isHome,
    $withMobileBottomBar: isRootLinkActive,
    $withReactNativeWebview: isReactNativeWebview
  }, createElement(SuspenseHandler, null, children)) : createElement(MainSection.div, {
    $withMobileAppBar: isHome,
    $withMobileBottomBar: isRootLinkActive,
    $withReactNativeWebview: isReactNativeWebview
  }, createElement(SuspenseHandler, null, children)), createElement(AdaChatButton, {
    authed: true
  }))));
};

export const createLayout = dependencies => props => createElement(InternalLayout, _extends({}, dependencies, props));
import { RequestConfig } from 'packages/http-client/fetcher';
import { createJsonBodyRequestConfig } from 'packages/http-client/react-forms';
import { useHttpQuery } from 'packages/http-client/react';

export type OverlayIds = 'DashboardOverlay:NavigationUpdate';

export interface OverlayResponse {
  id: OverlayIds;
}

export enum FeatureName {
  VERSION_2_LAYOUT = 'oac:dashboard_v2_redesign'
}

export const createRespondRequest = (
  id: string,
  type: 'overlays'
): RequestConfig =>
  createJsonBodyRequestConfig({
    method: 'POST',
    url: `/v2/${type}/${id}/respond`,
    json: {},
    mutating: true
  });

export const createGetLayoutOverlayRequest = (): RequestConfig<
  OverlayResponse[]
> => ({
  method: 'GET',
  url: '/v2/overlays'
});

export const createLayoutOverlayViewedRequest = (
  id: OverlayIds
): RequestConfig<null> => ({
  method: 'POST',
  url: `/v2/overlays/${id}/view`
});

export const useFeatureRequest = (feature: FeatureName) =>
  useHttpQuery(createGetFeatureStatusRequest({ name: feature }));

export const createGetFeatureStatusRequest = ({
  name
}: {
  name: FeatureName;
}): RequestConfig<FeatureResponse> => ({
  url: `/v1/features/${name}`,
  method: 'GET'
});

export interface FeatureResponse extends WithAccessToken {
  features: { [name: string]: boolean };
}

export interface WithAccessToken {
  access_token: string;
}

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import __NS_DYNAMIC_REACT_CONTENT___0 from "./notification-bell.strings/content.default.js";
import { Content, createScopedReactContentImporter } from "packages/react-content/runtime";
const NS_DYNAMIC_REACT_CONTENT_MANAGER = createScopedReactContentImporter(undefined, {
  "default": () => __NS_DYNAMIC_REACT_CONTENT___0
});
import { createElement } from 'react';
import { useAnchor } from 'packages/react-nano-router';
import { useHttpQueryWithLoading } from 'packages/http-client/react';
import { Disclosure } from 'legos/typography';
import { SystemIcon } from 'legos/system-icon';
import { createNotificationsRequest } from 'apps/acp/packages/webapi';
import { useTheme } from './theme';
import { HeavyFontWeight, NotificationLink, NotificationV2Link, NotificationCounter, NotificationV2Counter } from './notification-bell.ccm.css';
export const NotificationBell = ({
  color,
  notificationsLink
}) => {
  const [isLoading, notifications] = useHttpQueryWithLoading(createNotificationsRequest());
  const {
    notificationCounterBackgroundColor
  } = useTheme();
  const notificationsAnchor = useAnchor(notificationsLink);
  const HeavyFont = HeavyFontWeight.as(Disclosure);
  const bellIconVariation = !isLoading && notifications && notifications.widgets.length > 0 ? 'bellSolid' : 'bellRegular';
  return createElement(NotificationLink.a, _extends({
    "data-testid": "bell-icon"
  }, notificationsAnchor), !isLoading && notifications && notifications.widgets.length > 0 ? createElement(NotificationCounter.span, {
    "data-testid": "notification-counter",
    $notificationCounterBackgroundColor: notificationCounterBackgroundColor
  }, createElement(HeavyFont, {
    color: "white"
  }, createElement(Content, {
    hash: "c0f4dcca356718bd1a123ce070f5fe14",
    devVariables: {
      notifications: notifications
    },
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }))) : '', createElement(SystemIcon, {
    type: bellIconVariation,
    color: color
  }));
};
export const NotificationV2Bell = ({
  notificationsLink
}) => {
  const [isLoading, notifications] = useHttpQueryWithLoading(createNotificationsRequest());
  const notificationsAnchor = useAnchor(notificationsLink);
  return createElement(NotificationV2Link.a, _extends({
    "data-testid": "bell-icon"
  }, notificationsAnchor), !isLoading && notifications && notifications.widgets.length > 0 ? createElement(NotificationV2Counter.span, {
    "data-testid": "notification-counter"
  }) : '', createElement(SystemIcon, {
    type: 'bell',
    color: 'darkNeutral'
  }));
};